:root{

  --toastify-color-success: #12B76A;
  --toastify-color-success-transparent: #12B76A38;
  --toastify-color-warning-transparent: #f1c40f38;
  --toastify-color-error-transparent: #e74c3c38;
  --toastify-color-info-transparent: #3498db38;
}
body{
  font-family: "Avenir Next", sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.next-cyr{
  font-family: "Avenir Next Cyr", sans-serif;
}

.Toastify__toast{
  border-left: solid 6px white;
  border-radius: 7px!important;
}

.Toastify__toast--success{
  border-left-color: var(--toastify-color-success);
}

.Toastify__toast--warning{
  border-left-color: var(--toastify-color-warning);
}
.Toastify__toast--error{
  border-left-color: var(--toastify-color-error);
}
.Toastify__toast--info{
  border-left-color: var(--toastify-color-info);
}

.Toastify__toast-icon{
  height: 2rem!important;
  width: 2rem!important;
  padding: 0.45rem;
  border-radius: 2rem;
}

.Toastify__toast--success .Toastify__toast-icon{
  background-color: var(--toastify-color-success-transparent);
}
.Toastify__toast--warning .Toastify__toast-icon{
  background-color: var(--toastify-color-warning-transparent);
}
.Toastify__toast--error .Toastify__toast-icon{
  background-color: var(--toastify-color-error-transparent);
}
.Toastify__toast--info .Toastify__toast-icon{
  background-color: var(--toastify-color-info-transparent);
}

svg{
  cursor: pointer;
}

ol {
  padding: 20px;
  list-style-type: decimal;

}
ol li {
  float: left;
  margin: 0 10px;
  padding: 0 10px;
  width: 100%;
  text-align: left;
}

